<template>
  <div class="index my-questions">
    <van-nav-bar title="我的客服" left-arrow @click-left="onBack" />
    <van-skeleton title :row="4" :loading="loading">
      <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item v-for="question in questions" 
            :key="question.id" 
            :name="question.id" 
            :title="question.question">
          {{question.answer}}
        </van-collapse-item>
      </van-collapse>
    </van-skeleton>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter } from "vue-router";
import DataService from "../../api/basic-data-service";
import "../../css/my-questions.scss";

export default {
  components: {},
  setup() {
    const state = reactive({
      questions: [],
      activeNames: [],
      loading: true,
    });
    const router = useRouter();
    const onBack = () => router.push("/my");
    onMounted(() => DataService.getQuestions().then(questions => {
        state.questions = questions;
        state.loading = false;
    }  ));
    return {
      ...toRefs(state),
      onBack
    };
  },
};
</script>
